@import '~antd/dist/antd.less';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .header {
    margin-bottom: 10px;
    font-size: 1.5em;
  }
  h1.title {
    color: @secondary-color;
  }
  .selectorHeader {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  }

  .contestBanner {
    border-width: 3px;
    width: 100%;
    margin: 10px;
    background-color: #124734;
    color: #fff;
    border-radius: 2px;
    span {
      text-decoration: underline;
    }
    a {
      color: #fff;
      text-decoration: underline;
    }
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .socialMediaButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
  }
  @media(max-width: 500px) {
    .socialMediaButtons {
      flex-direction: column;
      .loginButton {
        width: 100%;
      }
    }

  }
  .loginButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    margin: 10px 0;
  }
  .embeddedTableNameColumn {
    width: 100px;
  }


/* Team Colors */

.lv {
  &.primary {
      color: #fff;
      background-color: #000;
  }
  &.secondary {
      color: #000;
      background-color: #a5acaf;
  }
}

.ari {
  &.primary {
      color: #fff;
      background-color: #97233F;
      border: 1px solid #ffb612;
  }
  &.secondary {
      color: #000;
      background-color: #ffb612;
      border: 1px solid #97233F;
  }
}

.atl {
  &.primary {
      color: #fff;
      background-color: #a71930;
      border: 1px solid #000;
  }
  &.secondary {
      color: #fff;
      background-color: #000;
      border: 1px solid #a71930;
  }
}

.bal {
  &.primary {
      color: #fff;
      background-color: #241773;
      border: 1px solid #000;
  }
  &.secondary {
      color: #fff;
      background-color: #000;
      border: 1px solid #241773;
  }
}

.buf {
  &.primary {
      color: #fff;
      background-color: #00338D;
      border: 1px solid #C60C30;
  }
  &.secondary {
      color: #fff;
      background-color: #C60C30;
      border: 1px solid #00338D;
  }
}

.car {
  &.primary {
      color: #000;
      background-color: #0085CA;
      border: 1px solid #101820;
  }
  &.secondary {
      color: #fff;
      background-color: #101820;
      border: 1px solid #0085CA;
  }
}

.chi {
  &.primary {
      color: #fff;
      background-color: #0B162A;
      border: 1px solid #C83803;
  }
  &.secondary {
      color: #fff;
      background-color: #C83803;
      border: 1px solid #0B162A;
  }
}

.cin {
  &.primary {
      color: #000;
      background-color: #FB4F14;
      border: 1px solid #000;
  }
  &.secondary {
      color: #fff;
      background-color: #000;
      border: 1px solid #FB4F14;
  }
}

.cle {
  &.primary {
      color: #fff;
      background-color: #311D00;
      border: 1px solid #FF3C00;
  }
  &.secondary {
      color: #000;
      background-color: #FF3C00;
      border: 1px solid #311D00;
  }
}

.dal {
  &.primary {
      color: #fff;
      background-color: #003594;
      border: 1px solid #041E42;
  }
  &.secondary {
      color: #fff;
      background-color: #041E42;
      border: 1px solid #003594;
  }
}

.den {
  &.primary {
      color: #000;
      background-color: #Fb4F14;
      border: 1px solid #002244;
  }
  &.secondary {
      color: #fff;
      background-color: #002244;
      border: 1px solid #Fb4F14;
  }
}

.det {
  &.primary {
      color: #fff;
      background-color: #0076B6;
      border: 1px solid #B0B7Bc;
  }
  &.secondary {
      color: #000;
      background-color: #B0B7B2;
      border: 1px solid #0076B6;
  }
}

.det {
  &.primary {
      color: #fff;
      background-color: #0076B6;
      border: 1px solid #B0B7Bc;
  }
  &.secondary {
      color: #000;
      background-color: #B0B7B2;
      border: 1px solid #0076B6;
  }
}

.gb {
  &.primary {
      color: #fff;
      background-color: #203731;
      border: 1px solid #FFB612;
  }
  &.secondary {
      color: #000;
      background-color: #FFB612;
      border: 1px solid #203731;
  }
}

.hou {
  &.primary {
      color: #fff;
      background-color: #03202F;
      border: 1px solid #A71930;
  }
  &.secondary {
      color: #fff;
      background-color: #A71930;
      border: 1px solid #03202F;
  }
}

.ind {
  &.primary {
      color: #fff;
      background-color: #002C5F;
      border: 1px solid #A2AAAD;
  }
  &.secondary {
      color: #000;
      background-color: #A2AAAD;
      border: 1px solid #002C5F;
  }
}

.jac {
  &.primary {
      color: #fff;
      background-color: #101820;
      border: 1px solid #D7A22A;
  }
  &.secondary {
      color: #000;
      background-color: #D7A22A;
      border: 1px solid #101820;
  }
}

.kc {
  &.primary {
      color: #fff;
      background-color: #E31837;
      border: 1px solid #FFB81C;
  }
  &.secondary {
      color: #000;
      background-color: #FFB81C;
      border: 1px solid #E31837;
  }
}

.lac {
  &.primary {
      color: #fff;
      background-color: #002A5E;
      border: 1px solid #FFC20E;
  }
  &.secondary {
      color: #000;
      background-color: #FFC20E;
      border: 1px solid #002A5E;
  }
}

.lar {
  &.primary {
      color: #fff;
      background-color: #002244;
      border: 1px solid #866D4B;
  }
  &.secondary {
      color: #000;
      background-color: #866D4B;
      border: 1px solid #002244;
  }
}

.mia {
  &.primary {
      color: #fff;
      background-color: #008E97;
      border: 1px solid #FC4C02;
  }
  &.secondary {
      color: #fff;
      background-color: #FC4C02;
      border: 1px solid #008E97;
  }
}

.min {
  &.primary {
      color: #fff;
      background-color: #4F2683;
      border: 1px solid #FFC62F;
  }
  &.secondary {
      color: #000;
      background-color: #FFC62F;
      border: 1px solid #4F2683;
  }
}

.ne {
  &.primary {
      color: #fff;
      background-color: #002244;
      border: 1px solid #C60C30;
  }
  &.secondary {
      color: #000;
      background-color: #C60C30;
      border: 1px solid #002244;
  }
}

.no {
  &.primary {
      color: #000;
      background-color: #D3BC8D;
      border: 1px solid #101820;
  }
  &.secondary {
      color: #fff;
      background-color: #101820;
      border: 1px solid #D3BC8D;
  }
}

.nyg {
  &.primary {
      color: #fff;
      background-color: #0B2265;
      border: 1px solid rgb(163,13,45);
  }
  &.secondary {
      color: #000;
      background-color: rgb(163,13,45);
      border: 1px solid #0B2265;
  }
}

.nyj {
  &.primary {
      color: #fff;
      background-color: #125740;
      border: 1px solid #0B2265;
  }
  &.secondary {
      color: #fff;
      background-color: #0B2265;
      border: 1px solid #125740;
  }
}

.phi {
  &.primary {
      color: #fff;
      background-color: #004C54;
      border: 1px solid #A5ACAF;
  }
  &.secondary {
      color: #000;
      background-color: #A5ACAF;
      border: 1px solid #004C54;
  }
}

.pit {
  &.primary {
      color: #000;
      background-color: #FFB612;
      border: 1px solid #101820;
  }
  &.secondary {
      color: #fff;
      background-color: #101820;
      border: 1px solid #FFB612;
  }
}

.sf {
  &.primary {
      color: #fff;
      background-color: #AA0000;
      border: 1px solid #B3995D;
  }
  &.secondary {
      color: #000;
      background-color: #B3995D;
      border: 1px solid #AA0000;
  }
}

.sea {
  &.primary {
      color: #fff;
      background-color: #002244;
      border: 1px solid #69BE28;
  }
  &.secondary {
      color: #000;
      background-color: #69BE28;
      border: 1px solid #002244;
  }
}

.tb {
  &.primary {
      color: #fff;
      background-color: #D50A0A;
      border: 1px solid #FF7900;
  }
  &.secondary {
      color: #000;
      background-color: #FF7900;
      border: 1px solid #D50A0A;
  }
}

.ten {
  &.primary {
      color: #fff;
      background-color: #0C2340;
      border: 1px solid #418FDE;
  }
  &.secondary {
      color: #000;
      background-color: #418FDE;
      border: 1px solid #0C2340;
  }
}

.was {
  &.primary {
      color: #fff;
      background-color: #773141;
      border: 1px solid #FFB612;
  }
  &.secondary {
      color: #000;
      background-color: #FFB612;
      border: 1px solid #773141;
  }
}
@primary-color: #0a1f8f;@secondary-color: #124734;@tertiary-color: #e04403;@info-color: @primary-color;@success-color: #124734;@processing-color: @primary-color;@error-color: rgba(224, 20, 7, 1.0);@highlight-color: #f6dfa4;@warning-color: rgba(224, 20, 7, 1.0);@normal-color: #231f20;@white: #fff;@black: #231f20;@layout-header-background: #231f20;@layout-header-color: #f6dfa4;