.profile {
    margin: 16px;
    padding: 16px;
}
.profileWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .profileDetails {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: space-between;
    }
}
.profileRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.profileImage {
    width: 200px;
    height: 200px;
    object-fit: cover;
    max-width: 100%;
    border-radius: 50%;
}
.profileChangePassword {
    margin-top: 12px;
}
.username {
    font-weight: 700;
    padding-left: 20px;
}
.loginForm {
    display: flex;
    flex-direction: column;
}

.loginForm > .ant-form-item {
    flex-direction: row;
}

.loginForm .ant-form-item-label {
    flex: 1
}
.loginForm .ant-form-item-control {
    flex: 2
}

.facebook-button {
    background-color: #4267B2;
    color:#fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.facebook-button > span {
    margin: 0 5px;
    min-width: 22px;
    min-height: 28px;
}

@primary-color: #0a1f8f;@secondary-color: #124734;@tertiary-color: #e04403;@info-color: @primary-color;@success-color: #124734;@processing-color: @primary-color;@error-color: rgba(224, 20, 7, 1.0);@highlight-color: #f6dfa4;@warning-color: rgba(224, 20, 7, 1.0);@normal-color: #231f20;@white: #fff;@black: #231f20;@layout-header-background: #231f20;@layout-header-color: #f6dfa4;