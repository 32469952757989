.header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    .headerText {
        font-size: 1.5em;
    }
    .headerButton {
        background-color: #f6dfa4;
        color: #231f20;
    }
    .headerUserDropdown {
        color: #f6dfa4;
        position: relative;
        left: 20px;
        font-size: 0.8em;
    }
}
@media (max-width: 767px) {
    .header {
        padding: 0 15px;
    }
}
.headerImage {
    width: 2em;
    height: 2em;
    margin: 0 20px;
}
@primary-color: #0a1f8f;@secondary-color: #124734;@tertiary-color: #e04403;@info-color: @primary-color;@success-color: #124734;@processing-color: @primary-color;@error-color: rgba(224, 20, 7, 1.0);@highlight-color: #f6dfa4;@warning-color: rgba(224, 20, 7, 1.0);@normal-color: #231f20;@white: #fff;@black: #231f20;@layout-header-background: #231f20;@layout-header-color: #f6dfa4;