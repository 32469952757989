.groupsContainer {
    margin: 10px 20px;

    .groupAvatar {
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.1);
        margin-right: 10px;
    }
}
.groupContainer {
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 0 15px;
    width: 100%;
    // div {
        // width: 100%;
    // }
    .backButton {
        color: #fff;
    }
    .groupRow {
        width: 100%;
    }
    .groupHeader {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        background-color: @primary-color;
        h1 {
            color: #fff;
            margin: 0.5em;
        }
        .createGroupButton {
            position: relative;
            right: 0px;
            color: #fff;
        }
        .groupName {
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            & h1, h2, h3, h4 {
                color: #fff;
                margin: 0 10px;
            }
        }
        .groupAvatar {
            width: 50px;
            height: 50px;
        }
    }
    .lockedGroup {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .groupLeaderboard {
        width: 100%;
    }
    .groupTable {
        th {
            vertical-align: bottom;
        }
    }
}
.image-wrapper {
    & img {
        margin: 0 10px;
    }
}
@primary-color: #0a1f8f;@secondary-color: #124734;@tertiary-color: #e04403;@info-color: @primary-color;@success-color: #124734;@processing-color: @primary-color;@error-color: rgba(224, 20, 7, 1.0);@highlight-color: #f6dfa4;@warning-color: rgba(224, 20, 7, 1.0);@normal-color: #231f20;@white: #fff;@black: #231f20;@layout-header-background: #231f20;@layout-header-color: #f6dfa4;