.rulesContainer {
    margin: 0 auto;
    text-align: left;
    padding: 15px;
    background-color: rgb(255, 255, 255, 0.8);
}
h1, h2, h3, h4 {
    padding: 3px 10px;
}
h1, h2, h3 {
    background-color: rgb(240, 242, 245);
    border-bottom: 2px solid #000;
    border-top-left-radius: 10px;
    margin: 0 -5px;
}
@primary-color: #0a1f8f;@secondary-color: #124734;@tertiary-color: #e04403;@info-color: @primary-color;@success-color: #124734;@processing-color: @primary-color;@error-color: rgba(224, 20, 7, 1.0);@highlight-color: #f6dfa4;@warning-color: rgba(224, 20, 7, 1.0);@normal-color: #231f20;@white: #fff;@black: #231f20;@layout-header-background: #231f20;@layout-header-color: #f6dfa4;