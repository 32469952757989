
.GamePreview {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    margin: 10px;
    max-width: 640px;
}
@media (max-width: 767px) {
    .GamePreview {
        margin: 0 6px;
    }
}
.game-header-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #231f20;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    position: relative;
    .left-arrow {
        position: relative;
        left: 0;
        color: #fff;
    }
    .game-header {
        color: #fff;
        margin-bottom: 0;
    }
}
.left-arrow {
    color: #fff;
}
.gameWeather {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
h4.game-header {
    color: #fff;
    white-space: normal;
}
.headerRow {
    border-bottom: 1px solid rgba(35, 31, 32, 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
}
.headerRow > .gameDate {
    font-weight: 700;
}
.crowdTable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    .ant-row {
        width: 100%;
        background-color: #0b0a07;
        .ant-typography {
            color: #fff;
        }
    }
    .resultsRow {
        background-color: #fff;
        .ant-typography {
            color: #0b0a07;
        }
    }
}

.oddsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 10px 20px;
    .oddsChangeText {
        color: #cc2936;
        font-weight: 700;
        cursor: pointer;
    }
}
.predictionRow {
    padding: 5px 3px;
    /* border-bottom: 1px solid rgba(35, 31, 32, 0.2); */
    .noPrediction {
        text-align: center;
    }
}
.predictionScoreBox {
    font-weight: 700;
}

.crowdRow {
    background-color: rgba(0, 0, 0, 0.1);
}

.results {
    font-weight: 700;
}

.resultIcon {
    position: absolute;
    font-size: 0.8rem;
    top: 0.3rem;
    left: -0.4rem;
}
.resultTriangle {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 12px solid transparent;
    border-top: 12px solid;
}
.resultWin {
    color:#124734;
    border-top-color: rgba(18, 71, 52, 1.0);
}
.resultLoss {
    color: red;
    border-top-color: rgba(224, 20, 7, 1.0);
}
.stakes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.stakesRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.stakesCol {
    min-height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.stakesLabel {
    padding-right: 6px;
}

.stakeIcon {
    height: 30px;
    width: 30px;
    margin-top: -0.4em;
}
.stakeUnselected {
    opacity: 0.5;
    height: 30px;
    margin: 0;
}
.stakeSelected {
    border: #000 solid 1px;
    border-radius: 5px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
    margin: 0 2px;
}
.stakeCorrect {
    border: #124734 solid 3px;
}
.stakeIncorrect {
    border: #e01407 solid 2px;
}
.team {
    min-width: 45px;
    text-overflow: ellipsis;
}

.triangle-top-right {
	width: 0;
	height: 0;
	border-top: 140px solid #103252;
	border-left: 140px solid transparent;
}
.weatherIcon {
    width: 30px;
    height: 30px;
}

.chartContainer {
    margin: 10px;
    background-color: '#fff';
    max-width: 640px;
}

.chartButton {
    margin: 10px auto;
}
@primary-color: #0a1f8f;@secondary-color: #124734;@tertiary-color: #e04403;@info-color: @primary-color;@success-color: #124734;@processing-color: @primary-color;@error-color: rgba(224, 20, 7, 1.0);@highlight-color: #f6dfa4;@warning-color: rgba(224, 20, 7, 1.0);@normal-color: #231f20;@white: #fff;@black: #231f20;@layout-header-background: #231f20;@layout-header-color: #f6dfa4;